.cart.main.actions .action.continue, .cart.main.actions .action.clear, .cart.main.actions .action.update, .checkout-cart-index .action.apply.primary, .checkout-cart-index .cart-summary .checkout-methods-items .item .action.checkout {
    font-weight: 400;
    text-transform: capitalize;
}

.action.primary, .action.secondary, button.btn, .action.update, .action.continue {
    color: $color__white;
    background-color: $color__red;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    padding: 9px 28px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    border: 0;
    transition: 0.3s;
    display: inline-block;
    text-align: center;
    border-radius: 0;
    line-height: 22px;

    &:hover {
        border: 0;
    }

    &:hover, &:focus {
        transition: 0.3s;
        background-color: #333;
    }

    &.small {
        font-size: 12px;
        padding: 7px 15px;
    }
}

.action.secondary {
    color: $color__white;
    background-color: $color__red;
}

.action.continue, .action.viewcart {
    &:before {
        content: "";
    }

    border: 1px solid $color__black;
    color: $color__black;
    background-color: $color__white;
    padding: 7px 29px;
    text-transform: uppercase;
}
