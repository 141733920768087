.minicart-wrapper {
    .block-minicart {
        margin: 0;
        padding: 12px 25px 15px;
        list-style: none none;
        background: #fff;
        border-top: 1px solid #ccc;
        z-index: 100;
        box-sizing: border-box;
        display: none;
        top: 100%;
        text-align: left;
        right: 0;
        position: absolute;
        width: 320px !important;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

        .block-title {
            display: block;

            span {
                text-transform: uppercase;
                letter-spacing: 0;
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 10px;
                display: block;
                color: #000;
            }
        }

        .minicart-items-wrapper {
            border: 1px solid #ccc;
            border-left: 0;
            border-right: 0;
            overflow-x: auto;
            max-height: 290px;

            .product-item-details {

                .product-item-name a {
                    @include default-link();
                }

                .price-including-tax, .price-excluding-tax {
                    .price {
                        font-weight: 400;
                        font-size: 1.5rem;
                    }
                }

                .details-qty {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-right: 5px;
                    flex-wrap: wrap;

                    .label {
                        margin-right: 10px;
                    }

                    .actions {
                        margin-left: auto;
                        margin-top: 5px;
                    }

                    .item-qty {
                        width: 70px;
                    }
                }
            }
        }

        .subtotal {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .label {
                color: $color__black;
                text-transform: capitalize;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
            }

            .amount .price-wrapper .price {
                font-weight: 600;
                color: $color__red;
                font-size: 14px;
            }
        }

        .block-content > .actions {
            .primary.checkout {
                font-size: 14px!important;
            }

            .secondary {
                margin: 0 10px 15px;

                a.viewcart {
                    display: block;
                    width: 100%;
                }
            }
        }

    }

}
