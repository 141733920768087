.block-authentication {
    .block-title strong {
        font-weight: 600;
        font-size: 18px;
        text-transform: uppercase;
        color: $color__black;
        display: block;
        margin-bottom: 15px;
    }

    .block[class] ul {
        list-style: disc;
        margin-left: 15px;
    }

    .action.primary {
        line-height: 22px;
        font-size: 14px;
        font-weight: 400;
        padding: 9px 28px;
    }

    .block-new-customer .block-content {
        ul {
            list-style: disc;
            margin-left: 15px;
        }
    }

    .block-customer-login {
        form .fieldset {
            margin-bottom: 0;

            .actions-toolbar {
                margin-top: 10px;

                .action-login {
                    font-size: 14px;
                    padding: 9px 28px;
                }
            }
        }
    }
}

@media (min-width: 1024px) {
    aside.popup-authentication {
        .block-authentication {
            .middle {
                min-width: 415px;
            }
        }
    }
}

@media (min-width: 768px) {
    aside.popup-authentication {
        .modal-inner-wrap {
            width: 1146px;
            max-width: 100%;
        }

        .action-close {
            padding-right: 10px;
        }

        .modal-content {
            padding: 0 20px 45px 20px;
        }

        .block-authentication {
            .block[class].middle {
                padding-right: 40px;

                .actions-toolbar {
                    justify-content: space-between;
                    flex-direction: row-reverse;
                }

                .fieldset {
                    justify-content: space-between;
                }
            }

            .block-title {
                margin-top: 20px;
                margin-bottom: 0;
            }

            .block-content {
                font-size: 15px;
                line-height: normal;
                justify-content: space-between;

                .field:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }

        .actions-toolbar {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 30px;

            &:before,
            &:after {
                display: none;
            }

            > .primary {
                float: none;
            }

            > .secondary {
                float: none;
                padding: 0;
            }
        }

        a.action {
            font-size: 13px;
        }

        button.action {
            border-radius: 2px;
        }
    }
}

@media (max-width: 767px) {
    aside.popup-authentication {
        .block-authentication {
            .block-title {
                margin-bottom: 20px;
            }

            .block-content {
                .field {
                    margin-bottom: 20px;
                }
            }

            .actions-toolbar {
                margin-top: 25px;

                > .secondary {
                    padding-top: 0;
                }

                a.action {
                    font-size: 13px;
                }
            }
        }
    }
}
