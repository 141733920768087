.checkout-index-index .page-wrapper .header {
    justify-content: center;
}

.checkout-container {
    .opc-progress-bar {
        margin: 30px 0 -20px 0;
        display: flex;
        justify-content: center;

        .opc-progress-bar-item {
            &._active {
                &:before {
                    background: $color__red;
                }

                & > span:before {
                    background: $color__red;
                }
            }

            &._complete {
                &:before {
                    background: $color_green;
                }

                & > span:before {
                    background: $color_green;
                }
            }
        }
    }

    .opc-wrapper {
        padding-left: 30px;

        .checkout-shipping-method {
            .shipping-policy-block {
                span {
                    color: $color__gray;
                }
            }

             .primary {
                color: #fff;
                background-color: #ed1c24;
                border: none;

                &:focus, &:hover {
                    transition: .3s;
                    background-color: #333;
                }
            }
        }

        .checkout-payment-method {
            .payment-option-title .action-toggle {
                color: $color__gray;
            }
        }

        .checkout-payment-method {
            .comment {
                .payment-option {
                    &-content, &-title {
                        padding-left: 0;
                    }

                    &-title {
                        border-top: 0;
                        padding: 0 0 10px 0;
                    }

                    &-content {
                        textarea {
                            width: 250px;
                            height: 44px;
                        }
                    }
                }
            }
        }
    }

    .opc-summary-wrapper {
        padding-right: 30px;

        #opc-sidebar {
            background-color: $color__background_gray;
            padding-bottom: 10px;

            .opc-block-summary {
                > .title {
                    border: none;
                    text-transform: uppercase;
                    color: $color__black;
                    display: block;
                    font-size: 20px;
                    font-weight: 400;
                    margin-bottom: 4px;
                    padding-bottom: 1px;
                }

                .items-in-cart > .title strong {
                    font-size: 18px;
                    font-weight: 300;
                    color: #2a2a2a;
                }
            }
        }
    }
}

.form-shipping-address {
    .field {
        &.street {
            div[name="shippingAddress.street.1"] {
                display: none;
            }
        }
    }
}

.billing-address-form {
    .field {
        input[name="street[1]"] {
            display: none;
        }
    }
}
