.user-actions {
    display: flex;
    align-items: center;

    .account-switcher {
        .switcher-options ul.switcher-option {
            padding: 2px 5px;
        }

        .account-icon:before {
            @include header-icon($icon__user);
        }

        float: none;
        order: 1;

        .actions {
            .action {
                &::after {
                    display: none;
                }

                img {
                    width: 24px;
                }
            }

            .action.toggle.active {
                &::after {
                    display: none;
                }
            }
        }
    }

    .block-search .minisearch {
        .actions {
            button {
                top: 6px;

                &:before {
                    @include icon($icon__search);
                    color: $color__black;
                    font-size: 30px;
                }
            }
        }
    }

    .switcher {
        margin-left: 1rem;
    }

    .switcher-language {
        margin-right: 5px;
    }

    .minicart-wrapper {
        .action.showcart {
            &:before {
                @include icon($icon__cart);
                font-size: 24px;
                margin-right: 10px;
            }

            .counter.qty {
                top: 0;
                right: 0;
                margin-right: -10px;
                border-radius: 50%;
                background: $color__red;
                color: $color__white;
                height: 18px;
                line-height: 18px;
                display: block;
                min-width: 18px;
                overflow: hidden;
                padding: 0 3px;
                text-align: center;
                white-space: normal;
                font-size: 12px;
                position: absolute;
                margin-top: -10px;

                &.empty {
                    display: block;

                    .counter-number {
                        display: none;
                    }

                    &:after {
                        content: "0";
                    }
                }
            }
        }
    }
}
