.column.main .form.create.account {
    width: 100%;

    .legend {
        text-transform: uppercase;
        font-size: 20px;
        letter-spacing: 0.1em;
        font-weight: 600;
        color: $color__black;
        border-bottom: 1px solid $color__border_light_gray;
        padding: 10px 0;
        float: left;
        margin-left: 0;
    }

    .fieldset.create {
        border: 1px solid $color__border_lighter_gray;
        border-radius: 0;
        padding: 0 20px 25px;
    }

    .actions-toolbar {
        margin: -60px 0 20px 0;
    }
}
