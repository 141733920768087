form {
    .cart.main.actions {
        .action.continue {
            &:before {
                content: "";
            }

            border: 1px solid $color__black;
            color: $color__black;
            background-color: $color__white;
            padding: 7px 29px;
        }
    }

    .cart.table-wrapper {
        .col.qty {
            .input-text {
                width: 70px;
            }
        }
    }
}
