ul.category-list {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 0;
    padding-left: 0;

    color: $color__gray;
    border: 1px solid $color__border_lighter_gray;

    li {
        list-style: none;
        margin-bottom: 0;
    }

    .level-0 {
        padding: 15px;
        border-bottom-color: rgb(237, 237, 237);
        border-bottom-style: solid;
        border-bottom-width: 1px;

        &.active {
            background: #efefef;
            position: relative;
            font-weight: bold;
            margin-right: -16 px;
        }

        .item-wrapper {
            display: flex;
            justify-content: space-between;
        }

        a {
            font-weight: bold;
            width: calc(78%);
        }
    }

    ul.child-categories {
        display: none;

        &.active {
            display: block;
        }

        .level-1 {
            padding: 10px 0;

            &.active {
                background: $color__white;
                border: 1px solid $color__border_lighter_gray;
                border-width: 1px 0 1px 1px;
                position: relative;
                font-weight: bold;
                margin-right: -16px;
            }

            a {
                padding-left: 15px;
                width: calc(78%);
                font-weight: normal; /** todo var */
                &.active {
                    font-weight: bold;
                    text-decoration: underline;
                }
            }
        }
    }

    .close-subcategories:before {
        @include icon($icon__min);
    }

    .open-subcategories:before {
        @include icon($icon__plus);
    }
}

@media (max-width: $screen__medium) {
    ul.category-list {
        display: none;
    }
}
