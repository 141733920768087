
input[type="text"], input[type="password"], input[type="url"], input[type="tel"], input[type="search"], input[type="number"], input[type="datetime"], input[type="email"] {
    background: #fff;
    background-clip: padding-box;
    border: 1px solid $color__border_light_gray;
    border-radius: 0;
    font-size: 14px;
    height: 40px;
    line-height: 1.42857143;
    padding: 0 10px;
    vertical-align: baseline;
    width: 100%;
    transition: all 300ms;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    box-sizing: border-box;
    box-shadow: none !important;
}

form {
    .fieldset {
        .field .label {
            color: $color__black;
            font-weight: 400;
            padding: 0;
            margin: 0 0 8px;
            font-size: 13px;
            display: block;
            text-align: left;
        }

        .field.required:not(.checkout-agreement) > .label:after {
            content: '*';
            display: inline-block;
            color: $color__red;
            padding-left: 3px;
            margin: 0;

        }
    }
}

textarea {
    background: $color__white;
    background-clip: padding-box;
    border: 1px solid $color__border_light_gray;
    border-radius: 0;
    font-size: 14px;
    height: auto;
    margin: 0;
    padding: 10px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    resize: vertical;
    box-shadow: none;
}

.fieldset.create,
.fieldset.login {
    .field.choice {
        &:before {
            width: 100%;
        }
    }

    & > .field:not(.choice) {
        margin: 0;

        .control {
            display: block;
            float: none;
            width: auto;
        }

        & > .label {
            float: left;
            width: auto;
        }
    }
}
