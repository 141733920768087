@import 'swiper';

.swiper-slide {
    box-sizing: border-box;
}

.swiper-button-lock {
    display: none;
}

@screen max-screen($screen__medium) {
    .swiper-products {
        &__item {
            width: 45%;
        }
    }

    .swiper-nav {
        display: none;
    }
}
