@import 'sidebar/categories';

.page-products {
    .product-list-wrapper {
        &.loading {
            opacity: 0.5;
        }

        .toolbar-products {
            border-bottom: 1px solid $color__border_gray;
            padding: 0 0 20px 0;
            align-items: center;
            display: flex;

            select {
                font-family: "Helvetica Neue";
                width: auto;
                border: none;
                height: 38px;
                margin: 0 5px 0 0;
            }

            .limiter {
                display: block;
                vertical-align: top;
                float: left;
                border: 1px solid $color__border_gray;
                white-space: nowrap;
                padding: 0 0 0 20px;
            }

            .toolbar-sorter {
                vertical-align: top;
                float: left;
                border: 1px solid $color__border_gray;
                white-space: nowrap;
                padding: 0 0 0 20px;

                .sorter-label:after {
                    content: ':';
                }

                .sorter-action {
                    font-size: 14px;
                    width: 38px;
                    height: 38px;
                    text-align: center;
                    display: inline-block;
                    vertical-align: top;
                    line-height: 38px;
                    border-left: 1px solid $color__border_gray;
                    color: #bdbdbd;

                    &:before {
                        font-size: 14px;
                        @include icon($icon__sort_asc);
                    }

                    &.sort-desc {
                        &:before {
                            @include icon($icon__sort_desc);
                        }
                    }

                }
            }

            label {
                color: #a1b1bc;
                font-weight: 400;
                font-size: 14px;
            }
        }

        .limiter-text {
            display: none;
        }
    }
}

.list.products-list {
    .product-item {
        padding: 15px;
        border-bottom: 1px solid #eaeaea;

        &:nth-child(even) {
            background: rgba(0, 0, 0, 0.04);
        }

        .product-item-photo {
            display: none;
        }

        .product-item-details {
            display: flex;
            margin-top: 0;

            .left-column {
                display: flex;
                flex: 1;


                .product-item-name {
                    width: 25%;
                    margin: 0;
                }

                .product-item-link {
                    font-size: 16px;
                    color: #333;
                    line-height: 1.1;
                    text-decoration: none;
                    -webkit-transition: all 300ms;
                    font-weight: 500;

                    &:hover {
                        text-decoration: none;
                        color: $color__red;
                    }
                }

                .product-list-attributes {
                    margin-top: 5px;
                    display: flex;
                    width: 50%;
                    padding-left: 20px;

                    .product-list-attribute {
                        width: 25%;
                        min-height: 1px;
                    }
                }

                .price-box {
                    width: 16.6%;
                    margin: 0;
                }
            }

            .right-column {
                display: flex;
                align-items: flex-end;

                i.add-to-cart-icon:before {
                    @include icon($icon__cart_plus);
                }
            }
        }
    }
}

.catalog-product-view, .checkout-cart-index {
    .page-wrapper {
        & > main {
            width: 100%;
        }
    }
}

.block-upsell-container,
.block-related-container,
.block-crosssell-container {
    .swiper-nav {
        display: flex;
        column-gap: 14px;
    }

    .swiper-products__item {
        border: 1px solid $color__border_gray;

        .product-item-actions {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;

            button.action.tocart {
                padding: 5px 10px;
                text-transform: unset;
            }
        }
    }

    .swiper-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .swiper-nav__button--next,
    .swiper-nav__button--prev {
        cursor: pointer;
        height: fit-content;
    }

    .swiper-nav__button--prev {
        transform: rotate(180deg);
        margin-top: -2px;

        &:before {
            @include icon($icon__chevron_right);
        }
    }

    .swiper-nav__button--next {
        height: fit-content;

        &:before {
            @include icon($icon__chevron_right);
        }
    }

    .product-item-details {
        flex-direction: column;
    }

    .swiper-wrapper {
        .product-item {
            display: flex;
            justify-content: center;
            margin: 0;
        }
    }
}


.products-grid {
    .product-item-info {
        .product-item-details {
            display: flex;

            .left-column {
                .price-box {
                    margin: 0;
                }

                .product-item-name {
                    line-height: 1.1;
                }

                .price-including-tax {
                    .price {
                        font-weight: 400;
                    }

                    &:before {
                        content: attr(data-label) ': ';
                        font-size: 1.1rem;
                    }
                }

                .price-excluding-tax {
                    .price {
                        font-size: 14px;
                        font-weight: 400;
                    }

                    &:before {
                        font-size: 1.1rem;
                    }
                }
            }

            .right-column {
                align-self: center;

                .tocart {
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    font-size: 16px;
                    border: 1px solid $color__red;
                    text-align: center;
                    padding: 0;
                    line-height: 36px;
                    background-color: $color__red;
                    color: $color__white;
                    top: 24px;

                    i.add-to-cart-icon:after {
                        @include icon($icon__cart_plus);
                    }
                }
            }
        }
    }
}

.pagination-toolbar-products {
    display: flex;
    justify-content: center;
}

@media (max-width: $screen__medium) {
    .list.products-list {
        .product-item {
            .product-item-details {
                flex-direction: column;

                .left-column {
                    flex-wrap: wrap;
                    flex-direction: column;

                    .product-item-name {
                        width: 100%;
                    }

                    .product-list-attributes {
                        width: 100%;
                        padding: 0;
                    }

                    .price-box {
                        margin-top: 10px;
                    }
                }

                .right-column {
                    margin-top: -10px;
                    justify-content: end;
                }
            }
        }
    }
}
