.cms-index-index {
    .featured-image-home {
        min-height: calc(100vh - 115px);
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            max-width: 50vw;
            height: 100%;
            opacity: .7;
            z-index: -1;
            background: url("../Magento_Cms/images/t-transparent.svg") no-repeat bottom right;
            background-size: contain;
        }

        img {
            position: fixed;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            left: 0;
            z-index: -1;
        }

        .featured-image-content {
            margin-left: -20%;
            width: 35%;
            border-left: 16px solid rgba(255, 0, 0, 0.8);
            border-radius: 10px;
            overflow: hidden;

            p {
                padding: 4rem;
                background-color: white;
                margin-bottom: 0;
                font-weight: 600;
                text-align: left;
                color: black;
                font-size: 2.25rem;
            }
        }
    }

    .banner-container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        background-color: $color__banner_container;

        .homepage-banners {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 15px 0 15px;

            &__item {
                margin: -90px 2rem 4rem;
                width: calc(25% - 6rem);
                display: flex;
                flex-direction: column;

                &:last-of-type {
                    .banner-title {
                        background-color: rgba(57, 154, 157, 0.8);
                    }
                }

                .banner-title {
                    z-index: 2;
                    height: 90px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(255, 0, 0, 0.8);

                    span {
                        color: $color__white;
                        font-weight: 700;
                        letter-spacing: 0;
                    }
                }

                .banner-image {
                    overflow: hidden;

                    &:hover {
                        img {
                            transform: scale(1.1);
                        }
                    }

                    img {
                        min-width: 100%;
                        height: auto;
                        display: block;
                        transform: scale(1);
                        transition: all .3s ease-out 0s;
                    }
                }
            }
        }
    }

    /** The following is required to align the footer with the bottom of the banners. */
    footer {
        margin-top: 0 !important;
    }

    .footer.content {
        margin-top: 0;
    }

    .column.main {
        padding-bottom: 0;
    }
}

.cms-home {
    .columns {
        .column.main {
            padding: 0;
        }
    }
}

@media (max-width: $screen__medium) {
    .cms-home {
        .columns {
            .featured-image-home {
                &:after {
                    max-width: 100%;
                    background-size: contain;
                }

                .featured-image-content {
                    padding: 0;
                    margin: 0;
                    width: 100%;
                }
            }
        }

        .banner-container {
            margin: 0 -15px;

            .homepage-banners {
                padding: 15px;
                flex-direction: column;

                &__item {
                    margin: 0 0 50px 0;
                    width: 100%;

                    &:first-of-type {
                        margin-top: -70px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .page-wrapper {
        flex-wrap: wrap;
    }
}

@media (max-width: 1280px) and (min-width: $screen__medium) {
    .cms-home {
        .banner-container {
            .homepage-banners {
                &__item {
                    margin: 0px 2rem 4rem;
                    width: calc(50% - 6rem);
                    justify-content: space-around;

                    &:nth-child(-n + 2) {
                        margin-top: -70px;
                    }
                }
            }
        }

        .columns {
            .featured-image-home {
                &:after {
                    max-width: 100%;
                    background-size: contain;
                }

                .featured-image-content {
                    padding: 0;
                    margin: 15px;
                    width: 100%;
                }
            }
        }
    }
}
