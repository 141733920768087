.cart-popup {
    .modal-content {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .modal-inner-wrap {
        width: 350px;

        .action-close {
            padding: 0;
        }

        .cart-popup__product-title {
            background-color: #e5efe5;
            color: $color__success;
            padding: 15px;
            text-align: center;
            line-height: 1.4;

            a {
                color: $color__success;
                font-weight: bold;
            }

        }

        .cart-popup__contents {
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
                max-width: 100px;
            }
            .cart-subtotal-container {
                text-align: center;
                color: #222;
                font-weight: 600;

                button {
                    margin-top: 10px;
                }
            }
        }

        .modal-header {
            h1 {
                display: none;
            }
        }
    }

    .cart-popup__bottom button {
        width: 100%;
    }
}
