.page-title-wrapper {
    background-image: url('../Magento_Theme/images/header-image.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    padding: 60px 15px;
    text-align: center;
    position: relative;
    margin-bottom: 50px;

    h1 {
        color: #fff;
        font-weight: 700;
        font-size: 30px;
        padding: 0;
        border: none;
        display: block;
        margin: 0;
        position: relative;
        text-transform: uppercase;
    }

    .breadcrumbs {
        position: relative;
        padding: 0;
        margin: 0;
    }
}

@media (max-width: $screen__medium) {
    .page-header {
        margin-bottom: 0;
    }
    .page-title-wrapper {
        margin: 0 -15px 10px -15px;
    }
}
