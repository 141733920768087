.page-wrapper {
    .header.content {
        background-color: $color__white;

        &:before, &:after {
            display: none;
        }

        display: flex;
        justify-content: space-between;
        padding: 30px 60px 10px 60px;

        .logo {
            margin: 0;

            img {
                max-width: unset;
            }
        }

        .search-button {
            &:before {
                @include header-icon($icon__search);
                font-size: 28px;
                font-weight: 500;
            }
        }

        .top-nav {
            .top-nav__item {
                text-transform: lowercase;
                font-size: 1.5rem;
                letter-spacing: 0.05em;
                font-weight: 500;
                padding: 0 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .hamburger {
            width: 32px;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            z-index: 998;

            &.open {
                top: 60px;

                span {
                    &:nth-of-type(1) {
                        transform: rotate(45deg) translate(4px, 4px);
                    }

                    &:nth-of-type(2) {
                        display: none;
                    }

                    &:nth-of-type(3) {
                        transform: rotate(-45deg) translate(2px, -3px);
                    }
                }

            }

            span {
                width: 100%;
                height: 2px;
                display: block;
                background-color: #000;
                margin: 8px 0;
                transition: all 0.3s ease;
            }
        }
    }
}

.top-nav {
    margin-left: 100px;

    .top-nav__list {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        .nav-item {
            a {
                color: black;
            }

            a:hover {
                color: #dd7870;
                text-decoration: none;
            }
        }

    }
}

@media (max-width: 1280px) {
    body:not(.offcanvas-active) {
        .header.content {
            display: flex;
            flex: 1;

            .block-search {
                display: none;
            }

            .top-nav__list {
                display: none;
            }

            .account-dropdown-wrapper {
                display: none;
            }

            .account-switcher {
                display: none;
            }

            .search-button {
                display: none;
            }

            .switcher-language {
                display: none;
            }

            .minicart-wrapper {
                display: none;
            }
        }
    }

    body.offcanvas-active {
        overflow-y: hidden;
        .header.content {
            position: fixed;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            right: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            background: white;
            z-index: 5;
            padding-top: 100px;

            .logo {
                display: none;
            }

            .user-actions {
                align-items: center;
                justify-content: space-between;

                height: 50px;
                order: 1;
                width: 100%;
                padding: 0 10px;

                .block-search {
                    position: absolute;
                    top: 190px;
                    transform: translateY(100%);
                    display: none;
                    width: 94%;

                    input {
                        left: 0;
                        height: 40px;
                    }
                }

                .icons {
                    width: 22px;
                    cursor: pointer;
                }

                .account-dropdown-wrapper {
                    display: block;
                    margin: 0;
                    float: none;
                    order: 1;

                    .actions {
                        .action {
                            &::after {
                                display: none;
                            }
                        }

                        .action.toggle.active {
                            &::after {
                                display: none;
                            }
                        }

                        img {
                            width: 24px;
                        }
                    }
                }

                .search-button {
                    order: 2;
                    display: block;
                }

                .switcher-language {
                    display: block;
                    float: none;
                    order: 3;

                    .dropdown {
                        .action {
                            strong {
                                font-weight: 900;
                            }
                        }
                    }
                }

                .minicart-wrapper {
                    display: block;
                    float: none;
                    margin: 0;
                    order: 4;

                    a.action.showcart {
                        height: fit-content;
                        background: $color__blue;
                        border-radius: 5px;
                        width: fit-content;
                        padding: 0.5rem 15px;
                        font-size: 14px;
                    }


                    .mage-dropdown-dialog {
                        .block-minicart {
                            width: 70%;
                            position: fixed;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            z-index: 5;
                            min-width: 0;
                            margin-top: 0;
                            border: 0;
                            border-left: 1px solid #bbbbbb;

                            .action.close {
                                display: none;
                            }
                        }
                    }

                    .showcart {
                        white-space: nowrap;
                        text-decoration: none;
                        cursor: pointer;
                        display: flex;
                        position: relative;
                        margin: 0 10px;
                        color: #fff;

                        &::before {
                            color: #fff;
                        }

                        .text {
                            clip: unset;
                            width: unset;
                            overflow: unset;
                            position: inherit;
                            margin-right: 10px;
                            margin-top: 7px;
                        }
                    }
                }
            }
        }

        .top-nav {
            padding: 2rem;
            order: 2;

            .top-nav__list {
                flex-flow: column;
                align-items: flex-start;

                .nav-item {
                    text-transform: lowercase;
                    font-size: 1.5rem;
                    letter-spacing: 0.05em;
                    font-weight: 500;
                    padding: 10px 10px;
                }
            }
        }

        .top-nav__mobile {
            order: 6;
            flex: 1;
            position: absolute;
            top: 50px;
            right: 15px;
        }
    }

    .top-nav {
        margin-left: 0;
        &__mobile, .hamburger {
            cursor: pointer;
        }
    }

    .page-wrapper .header.content {
        padding: 15px;
    }
}

@media (min-width: $screen__medium) {
    .header {
        display: flex;
        align-content: flex-Start;
        flex-direction: row;

        .block-search {
            position: absolute;
            transform: translateY(105%);
            right: 20px;
            display: none;
            width: 50%;

            .block-content {
                .form {
                    .search {
                        .control {
                            .input-text {
                                border: none;
                                box-shadow: 0px 0px 3px 0px #000000 !important;
                                color: #000000;
                                text-align: left;
                                font-size: 16px;
                                height: 46px;
                                padding: 0 15px;
                            }
                        }
                    }
                }
            }
        }

        .search-button {
            order: 2;
        }

        .switcher-language {
            float: none;
            order: 3;

            .dropdown {
                .action {
                    strong {
                        font-weight: 900;

                    }
                }
            }
        }

        .minicart-wrapper {
            float: none;
            display: block;
            margin-left: 0;
            order: 4;

            a.action.showcart {
                height: fit-content;
                background: $color__blue;
                border-radius: 5px;
                width: fit-content;
                padding: 0.5rem 10px;
                font-size: 14px;
            }

            .showcart {
                white-space: nowrap;
                text-decoration: none;
                cursor: pointer;
                display: flex;
                position: relative;
                color: #fff;
                height: 35px;
                padding-top: 0;

                &::before {
                    color: #fff;
                }

                &::after {
                    color: #fff;
                }

                &:hover {
                    color: #fff;

                    &::before {
                        color: #fff;
                    }
                }

                .text {
                    clip: unset;
                    width: unset;
                    overflow: unset;
                    position: inherit;
                    margin-right: 5px;
                    margin-top: 7px;
                }
            }

            .showcart.active {
                white-space: nowrap;
                text-decoration: none;
                cursor: pointer;
                display: flex;
                position: relative;
                color: #fff;
                height: 35px;
                padding-top: 0;

                &::before {
                    color: #fff;
                }

                &::after {
                    color: #fff;
                }

                &:hover {
                    color: #fff;

                    &::before {
                        color: #fff;
                    }
                }

                .text {
                    clip: unset;
                    width: unset;
                    overflow: unset;
                    position: inherit;
                }
            }
        }

        .minicart-wrapper.active {
            .ui-dialog {
                .block-minicart {
                    right: 10px;
                }
            }
        }

        .icons {
            width: 21px;
            cursor: pointer;
        }

        .top-nav {
            display: flex;

            .nav-item {
                text-transform: lowercase;
                font-size: 1.5rem;
                letter-spacing: 0.05em;
                font-weight: 500;
                padding: 10px 10px;
            }
        }
    }

    .sections.nav-sections {
        display: none;
    }

    .body.offcanvas-active {
        .header.content {
            display: none;
        }
    }
}

@media (min-width: 1281px) {
    .header {
        .top-nav__mobile {
            display: none;
        }
    }
}

body:not(.offcanvas-active) {
    .page-header {
        position: sticky;
        top: 0;
        z-index: 99;
    }
}

