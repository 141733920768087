@mixin icon($icon) {
    font-family: 'icon';
    -webkit-font-smoothing: antialiased;
    content: $icon;
}

@mixin header-icon($icon) {
    @include icon($icon);
    font-size: 20px;
    cursor: pointer;
    padding: 0 1rem;
    font-weight: 400;
}

@mixin default-link() {
    color: #333;
    transition: all 300ms;

    &:visited, &:active {
        color: #333;
    }

    &:hover {
        text-decoration: none;
        color: $color__red;
    }
}
