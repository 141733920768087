.column.main {
    .form.password.forget {
        .fieldset .field-recaptcha {
            margin-left: 25.8%;
        }
        .field.note {
             margin-left: 25.8%;
         }
    }
}
