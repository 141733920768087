label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

a {
    @include default-link();
}

b, strong {
    font-weight: 900;
}

h4, .h4 {
    font-size: 20px;
}

h4, .h4, h5, .h5, h6, .h6 {
    margin-top: 10px;
    margin-bottom: 10px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #000;
    font-weight: 500;
    line-height: 1.1;
}

h5 {
    font-size: 16px;
}

h1 {
    font-weight: 700;
    font-size: 30px;
}

h3 {
    font-weight: 700;
    font-size: 24px;
}

i {
    font-style: normal;
}

ul, ol {
    padding-left: 0;
    margin: 0;
}

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type*="date"],
input[type="email"] {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.price-including-tax, .price-excluding-tax {
    .price {
        font-weight: 400;
        font-size: 1.5rem;
        color: $color__red;
    }

    &:before {
        font-size: 1.1rem;
    }
}

.price-including-tax {
    &:before {
        content: attr(data-label) ': ';
    }
}
