.checkbox-set {
    border: 0;
    margin: 0;
    padding: 0;
    .checkbox-set-option {
        margin-right: 10px;
    }
}

.checkbox-set-control {
    display: flex;
}
