.filters {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    .filter-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .filter {
            &:not(:last-of-type) {
                margin-right: 20px;
            }

            .filter-options-content {
                .price-filter {
                    display: flex;
                    align-items: center;

                    padding-right: 20px;

                    input[type="number"] {
                        max-width: 75px;
                    }

                    .from-text {
                        padding-right: 10px;
                    }

                    .to {
                        padding: 0 10px;
                    }
                }
            }
        }
    }

    .filter-buttons-wrapper {
        display: flex;

        button {
            height: 40px;
            max-width: 300px;
            margin-bottom: 40px;
            font-size: 12px;

            &:not(:last-of-type) {
                margin-right: 15px;
            }
        }
    }
}

