.category-list-widget {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .category-item {
        border-radius: 4px;
        border: 1px solid #ededed;
        margin-bottom: 30px;
        transition: box-shadow .3s;
        width: 33%;

        &:hover {
            box-shadow: 0 0 11px rgb(33 33 33 / 20%);
        }

        .category-wrapper {
            .category-item-link {
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    flex: 1;
                    min-width: 135px;
                    min-height: 135px;
                    margin-bottom: 5px;
                }

                h5 {
                    text-align: center;
                    font-weight: bold;
                    color: $color__red;
                    padding: 0 10px;
                    margin-top: 0;
                }
            }
        }
    }
}

@media (max-width: $screen__medium) {
    .category-list-widget {
        .category-item {
            width: 100%;
        }
    }
}
