body.customer-address-form {
    .company-switch {
        display: flex;

        .field.company-switch-option:not(:first-child) {
            margin-left: 10px;
        }
    }

    //RUB-192: add postcodeCheck to customer address edit.
    .field {
        &.street {
            .nested {
                .field.additional {
                    display: none;
                }
            }
        }
    }

    .field.error-wrapper {
        width: 100%;
        display: none;

        .message {
            &.error.address-validation-message {
                width: 74.2%;
                float: right;
            }
        }
    }

    .field.autocomplete {
        display: none;
    }

    .field.possible-addresses {
        display: none;
    }
}

@media (min-width: $screen__medium) {
    //RUB-165: Add margin to solve layout without a label.
    body.customer-address-form {
        .company-switch {
            margin-left: 25.8%;
        }
    }
}

