@import 'glider';

.catalog-product-view {
    .column.main {
        padding: 0 60px;

        .product-details {
            .attribute.description {
                padding-bottom: 30px;
            }
        }

        .product-info-main {
            display: flex;
            width: 100%;
            margin-bottom: 20px;
            justify-content: flex-start;
            align-items: center;

            .media {
                width: 60%;
                flex: 1;
                margin-right: 15px;

                .gallery-wrapper {
                    width: 450px;
                    flex: 2;

                    .glider-dot.active {
                        background: $color__red;
                    }

                    .glider-next, .glider-prev {
                        color: $color__black;
                        font-size: 50px;

                        &:hover, &:active, &:focus {
                            color: $color__red;
                            background: $color__white;
                            border: none;
                            box-shadow: none;
                        }
                    }

                    .glider-next {
                        right: -1.5em;
                    }

                    .glider-prev {
                        left: -1.5em;
                    }
                }
            }

            .prices-tier {
                background-color: $color__white;
            }

            .right-product-info {
                padding-top: 50px;
                flex: 2;

                .price-box {
                    .price-including-tax {
                        line-height: 1.6;

                        &:before {
                            content: attr(data-label) ': ';
                            font-size: 1.1rem;
                        }
                    }

                    .price-excluding-tax {
                        line-height: 1.6;

                        &:before {
                            font-size: 1.1rem;
                        }
                    }

                    .price {
                        display: block;
                        font-size: 24px;
                        color: $color__red;
                        font-weight: normal;
                    }
                }

                .prices-tier {
                    padding: 10px 0;
                }

                .delivery-times {
                    font-style: italic;
                    margin-bottom: -10px;
                }

                .box-tocart {
                    display: flex;

                    .control {
                        width: auto;
                        display: flex;

                        .input-text {
                            flex: 2;
                            width: 100px;
                            -moz-appearance: textfield;
                            background-color: transparent;
                            color: $color__black;
                            display: inline-block;
                            font-weight: 600;
                            padding: 10px 0;
                            text-align: center;
                            border-bottom: 1px solid rgba(0, 0, 0, 0.25);
                            border-top: 1px solid rgba(0, 0, 0, 0.25);
                        }

                        .edit-qty {
                            flex: 1;
                            color: #999;
                            cursor: pointer;
                            display: inline-block;
                            text-align: center;
                            font-size: 20px;
                            width: 45px;
                            line-height: 45px;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            border: 1px solid rgba(0, 0, 0, 0.25);
                            transition: all .5s ease 0s;
                            -webkit-transition: all .5s ease 0s;
                            -moz-transition: all .5s ease 0s;
                            -o-transition: all .5s ease 0s;
                            padding-left: 2px;
                        }
                    }
                }

                .actions {
                    padding-top: 0;

                    span {
                        font-size: 14px;
                        letter-spacing: 1.4px;
                    }
                }
            }
        }
    }

    table.additional-attributes {
        margin-bottom: 15px;
        width: 100%;

        tr {
            &:nth-child(2n) {
                background-color: #f6f6f6;
            }

            th {
                display: table-cell;
                width: 30%;
                color: $color__black;
                border: 1px solid $color__border_lighter_gray !important;
                border-radius: 0;
                padding: 10px 15px;
                text-align: left;
                font-size: 14px;
            }

            td {
                padding: 10px 15px;
                text-align: left;
                font-size: 14px;
                border: 1px solid $color__border_lighter_gray !important;
            }
        }
    }
}

@media (max-width: 870px) {
    .catalog-product-view {
        .column.main {
            padding: 0;

            .product-info-main {
                flex-direction: column;

                .media {
                    max-width: 300px;
                    width: 450px;

                    .gallery-wrapper {
                        width: 150px;

                        .glider-next {
                            right: -0.5em;
                        }

                        .glider-prev {
                            left: -0.5em;
                        }
                    }
                    margin-bottom: 15px;
                }

                .right-product-info {
                    margin: 0;
                    padding: 0;


                    .product-add-form {
                        margin-top: -15px;
                    }

                    .prices-tier {
                        font-size: 12px;
                    }

                    .box-tocart {
                        flex-direction: column;

                        .field.qty {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }
}
