.message.global {
    &.cookie {
        position: fixed;
        bottom: 0;
        width: 50%;
        z-index: 3;
        background-color: $color__white;
        margin: 0 auto;
        border: 1px solid black;

        .content {
            box-sizing: border-box;
            display: flex;
            max-width: 100%;
            margin: 0 auto;

            p {
                margin-top: 15px;
                flex: 1;
            }
        }
    }
}

@media (max-width: $screen__large) {
    .message.global {
        &.cookie {
            width: 100%;

            .content {
                flex-direction: column;
            }
        }
    }
}

@media (min-width: $screen__large) and (max-width: $screen__extra-large) {
    .message.global {
        &.cookie {
            width: 75%;
        }
    }
}
