.column.main .login-container {
    .block {
        min-height: 380px;
        border: 1px solid $color__border_lighter_gray;
        border-radius: 0;

        .block-title {
            padding: 10px 15px;
            margin-bottom: 0;

            strong {
                text-transform: uppercase;
                font-size: 20px;
                letter-spacing: 0.1em;
                font-weight: 600;
                color: $color__black;
            }
        }

        .block-content {
            padding: 20px 15px;
        }

        fieldset.login:after {
            content: "";
            margin-top: 0;
        }
    }

    .block-customer-login {

        .block-content .form-login .fieldset:last-child {
            margin-bottom: 0;
        }

        min-height: 372px;

        .remember-me-box {
            margin-bottom: 15px;
        }

        form .actions-toolbar {
            margin-left: 0;
        }
    }

    .block-new-customer {
        min-height: 372px;

        .actions-toolbar {
            left: 20px;
            bottom: 25px;

            .primary {
                border-radius: 0;
            }
        }
    }
}
