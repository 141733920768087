footer {
    .footer {
        background-color: #333d47;
        padding: 0;
        color: $color__white;

        a {
            color: $color__white;
            font-size: 12.5px;
        }

        &.content {
            margin-top: 0;
        }

        .footer-container {
            display: flex;
            flex-wrap: wrap;

            .footer-column {
                padding: 0 15px;
                min-width: 300px;

                li {
                    padding: 4px 0;
                }

                &:not(:first-child) {
                    margin-top: 40px;
                }

                &__first {
                    img {
                        margin-bottom: 20px;
                        max-width: 213px;
                        display: block;
                    }
                }
            }
        }
    }
}
