* {
    box-sizing: border-box;
}

body {
    font-size: 14px;
    color: #515151;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    .wide.fullwidth .container {
        width: 100%;
        padding: 0 60px;
    }
}

body .columns {
    width: 100%;
}


.cms-page-view.page-layout-1column {
    .column.main {
        border-radius: 15px 15px 0 0;
        background: white;
        width: 80%;
        margin: 40vh auto 0;
        padding: 60px;
        position: relative;
    }

    background: $color__banner_container;
}

@media (min-width: $screen__medium) {
    .cms-page-view.page-layout-1column {
        .column.main {
            margin: 0;
        }
    }
    .page-layout-1column .columns .column.main {
        width: 100%;
        padding: 0 60px;

    }
    .page-wrapper {
        .page-main {
            padding: 0;
        }
    }

    .page-layout-2columns-left {
        .columns {
            .column.main {
                width: 75%;
                padding: 0 15px;
            }

            .sidebar-main, .sidebar-additional {
                width: 25%;
                padding: 0 15px;
            }
        }
    }
}

.block-static-block.widget,
.block-cms-link.widget {
    margin: 0;
}
