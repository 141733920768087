.page-title-wrapper {
    .order-status, .order-date {
        color: $color__white;
    }

    .order-actions-toolbar .actions a {
        color: $color__white;
    }

    .actions-toolbar.order-actions-toolbar .action {
        margin-right: 0;

        &.print {
            float: none;
        }
    }
}


.sidebar {
    &.sidebar-main {
        .block-collapsible-nav .item.current a {
            border-color: $color__red;
        }
    }

    &.sidebar-additional {
        .block-reorder {
            .actions-toolbar {
                display: flex;
                margin: 0;
                max-width: 100%;
                justify-content: flex-start;

                .primary {
                    margin-right: 10px;

                    button {
                        font-size: 12px;
                        padding: 9px 10px;
                    }
                }

                .secondary {
                    display: flex;
                    align-items: center;

                    a.action {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
