.cms-page-view.page-layout-1column {
    .columns {
        background: white;
        margin: 15vh auto 0;
        width: 80%;
        position: relative;
        border-radius: 15px 15px 0 0;

        .column.main {
            padding: 60px;

            p {
                a.button {
                    color: #ffffff;
                    padding: 1.2%;
                }
            }
        }

        table {
            width: auto;
        }

        table > tbody > tr > td {
            padding: 0;
            vertical-align: middle;
        }
    }
}

@media (max-width: $screen__medium) {
    .cms-page-view.page-layout-1column {
        .columns {
            .column.main {
                margin: 0;
                padding: 20px;
            }
        }
    }
}
